import type { IDrawer } from '@types';

export const useDrawerStore = defineStore('drawerStore', () => {
  const activeList = ref<IDrawer[]>([]);
  const openList = ref<string[]>([]);

  return {
    activeList,
    openList,
  };
});
